@font-face {
  font-family: "Righteous";
  src: local("Righteous"), url(./fonts/Righteous-Regular.ttf) format("truetype");
  font-style: normal;
  font-weight: 400;
  font-display: auto;
}
@font-face {
  font-family: "Notosans-regular";
  src: local("Noto_Sans"),
    url(./fonts/Noto_Sans/NotoSans-Regular.ttf) format("truetype");
  font-style: normal;
  font-weight: 400;
  font-display: auto;
}
@font-face {
  font-family: "NotoSans-Bold";
  src: local("NotoSans-Bold"),
    url(./fonts/Noto_Sans/NotoSans-Bold.ttf) format("truetype");
  font-style: normal;
  font-weight: bold;
  font-display: auto;
}

body,
div,
p,
select,
button,
input,
table,
a,
span {
  font-family: "Notosans-regular";
}
.MuiAppBar-positionFixed {
  left: 20;
  right: 20;
}

.fontBold {
  font-family: "NotoSans-Bold";
}
.cursor {
  cursor: pointer;
}
.textCenter {
  text-align: center;
}
/* styles for tabs in rewards page */
.rewards-wrapper {
  .MuiTabs-flexContainer {
    justify-content: center;
  }
  button.MuiTab-textColorPrimary.Mui-selected {
    color: #1a7cfa;
    span {
      font-family: "NotoSans-Bold";
    }
  }
  .PrivateTabIndicator-colorPrimary-3 {
    background-color: #1a7cfa;
  }
  .MuiTab-wrapper {
    text-transform: none;
    font-size: 14px;
  }
  .MuiRadio-colorPrimary.Mui-checked {
    color: #1a7cfa;
  }
  .MuiSvgIcon-root,
  .MuiSvgIcon-root {
    width: 18px;
  }
  .rewardTab_options {
    .MuiTypography-root.MuiFormControlLabel-label {
      font-size: 14px;
    }
    .MuiFormControlLabel-root {
      margin-right: 45px;
    }
  }
}
//Add discount styles
.add_discount {
  .MuiFormControl-marginNormal {
    width: 100%;
  }
  .uploadfile {
    input {
      border: 1px dashed #ccc;
      padding: 10px;
      margin-top: 20px;
      border-radius: 5px;
      cursor: pointer;
    }
    &.errorFileUpload {
      input {
        border: 1px dashed red;
      }
    }
  }
  .select_desc {
    .MuiFormControl-root {
      min-width: 100%;
    }
  }
}
.discount_Item {
  width: 20%;
  box-shadow: 0 0 4px #ccc;
  padding: 10px;
  box-sizing: border-box;
  margin: 12px 12px;
  min-width: 180px;
  min-height: 220px;
  margin-right: 20px;
}
.discount_imagewrapper {
  min-height: 138px;
  display: "flex";
  align-items: "center";
  justify-content: "center";
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
// .listItemwrapper::after {
//     content: "";
//     flex: auto;
//     width: 29%;
// }

//loyality tab input
.select_desc {
  width: 45%;
}

.edit-wrapper input[type="number"]::-webkit-outer-spin-button,
.edit-wrapper input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media screen and (max-width: 1140px) {
  .discount_Item {
    width: 40%;
    box-shadow: 0 0 4px #ccc;
    padding: 10px;
    box-sizing: border-box;
    margin: 12px 12px;
    min-width: 180px;
    min-height: 220px;
  }
  .listItemwrapper::after {
    content: "";
    // flex: auto;
    width: 40%;
  }
}

// Landing page - marquee text styles
.marquee_text {
  text-transform: uppercase;
  -moz-animation: marquee 30s linear infinite;
  -webkit-animation: marquee 30s linear infinite;
  animation: marquee 30s linear infinite;
  white-space: nowrap;
}
@keyframes marquee {
  0% {
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  100% {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}
.marquee_wrapper {
  padding: 1px 0;
  background: rgba(255, 255, 255, 0.8);
  position: absolute;
  z-index: 1;
  bottom: 5px;
  left: 0;
  right: 0;
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
  -moz-backdrop-filter: blur(6px);
}
.banner_image {
  max-width: 100%;
  overflow: hidden;
  position: relative;
  .mobileBanner {
    display: none;
  }
  .mobile-banner {
    display: none;
    background-color: #1a7cfa;
    background-image: linear-gradient(#1a7cfa 80%, #0180f7 10%);
    box-sizing: "border-box";
    margin-bottom: -2px;
    padding: 35px 25px 15px 25px;
    box-sizing: border-box;
  }
}
.eachSection {
  display: flex;
  margin-top: 40px;
  justify-content: space-between;
  flex-wrap: wrap;
}
.sectionTwo {
  align-items: center;
}
.sectionFour {
  background: #f4f9ff;
  padding: 65px 0 30px 0;
  margin-bottom: 40px;
  flex-wrap: wrap;
}
.section_one_text {
  flex: 0 0 50%;
  padding: 0 70px;
  box-sizing: border-box;
}
.section_two_text {
  flex: 0 0 48%;
  padding: 0 50px 0 10px;
  box-sizing: border-box;
}
.section_three_text {
  flex: 0 0 48%;
  padding: 0 70px;
  box-sizing: border-box;
}
.section_four_text {
  flex: 0 0 48%;
  padding: 0 70px;
  box-sizing: border-box;
}
.section_one_image {
  flex: 0 0 40%;
}
.section_two_image {
  flex: 0 0 46%;
}
.section_three_image {
  flex: 0 0 45%;
}
.section_four_image {
  flex: 0 0 50%;
}
.mobileSectionOne {
  display: none;
}
.footer_content {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  margin-bottom: 30px;
  align-items: center;
}
.navList {
  display: flex;
  p {
    margin: 0 15px;
  }
}

//login page styles
.login_btn {
  .MuiButton-label {
    font-family: "NotoSans-Bold";
    text-transform: none;
  }
}

//loyalty page styles
.loyalty_wrapper {
  .add_tierBtn {
    border: 1px solid #1a7cfa;
    width: 120px;
    .MuiButton-label {
      font-family: "NotoSans-Bold";
      text-transform: none;
    }
  }
  .loyalty_btn {
    .MuiButton-label {
      font-family: "NotoSans-Bold";
      text-transform: none;
    }
  }
}

//Balance screen
.balance-wrapper {
  .MuiTabs-flexContainer {
    justify-content: center;
  }
  button.MuiTab-textColorPrimary.Mui-selected {
    color: #1a7cfa;
    span {
      font-family: "NotoSans-Bold";
    }
  }
  .PrivateTabIndicator-colorPrimary-3 {
    background-color: #1a7cfa;
  }
  .MuiTab-wrapper {
    text-transform: none;
    font-size: 14px;
  }
  .MuiTabs-root {
    border-bottom: 1px solid #ccc;
    margin: 0 20px;
  }
  .MuiPaper-elevation1 {
    box-shadow: none;
  }
  .MuiTab-root {
    min-width: 210px;
  }
  .amountBlockWrapper {
    padding: 8px 20px;
    flex-wrap: wrap;
  }
  .balancePill {
    padding: 15px;
    span.fontBold {
      margin-left: 10px;
      width: "33%";
    }
  }
}

//transfer tab styles
.transfer_main {
  padding: 24px 18px;
  .balance_radio {
    span.MuiFormControlLabel-label {
      font-size: 14px;
    }
  }
  .initiate_button {
    .MuiButton-label {
      text-transform: none;
      font-family: "NotoSans-Bold";
      font-size: 14px;
    }
  }
  .datePicker {
    .MuiFormControl-root {
      width: 100%;
    }
  }
  .eachTransfer_Section {
    padding: 30px 25px;
  }
  .transferFormWrapper {
    display: flex;
    margin-left: 40px;
    align-items: center;
    flex-wrap: wrap;
  }
  .transferFormContainer {
    flex: 1;
    padding: 20px 50px 50px 50px;
    box-sizing: border-box;
  }
  .amountInput {
    display: flex;
    align-items: flex-end;
  }
}

//header styles
.mobileHeader {
  display: none;
}

//Create Merchant styles
.createMerchant {
  .MuiFormControl-root {
    width: 100%;
  }
}
.signupWrapper {
  background-color: #f0f5fd;
  min-height: 90vh;
  .MuiInputLabel-root,
  .MuiInputBase-input {
    font-size: 14px;
  }
  .customBtn {
    width: 200px;
  }
  .date-picker {
    .MuiFormControl-root {
      width: 100%;
    }
  }
}

// OTP screen styles
.otp-input {
  input {
    text-align: center;
  }
}

//Dashboard main styles
.dashboardMain {
  margin-left: 200px;
  padding-top: 85px;
  padding-bottom: 40px;
}
//loyality table
.table_mobile {
  display: none !important;
}
//loyality table end
@media screen and (max-width: 767px) {
  //landing page styles
  .marquee_wrapper {
    display: none;
  }
  .banner_image {
    margin-top: -22px;
    .mobileBanner {
      display: block;
    }
    .webBanner {
      display: none;
    }
    .mobile-banner {
      display: block;
    }
    .bannerText {
      color: white;
      text-transform: uppercase;
      margin: 0;
      font-size: 18px;
    }
    .bannerHead {
      font-size: 42px;
    }
    .transText {
      margin-top: 10px;
      margin-bottom: 30px;
    }
    .bannerButtons {
      .MuiButton-root {
        display: block;
        width: 100%;
        padding: 10px;
      }
      .createBtn {
        background: white;
        margin-bottom: 18px;
        .MuiButton-label {
          font-family: "NotoSans-Bold";
          text-transform: none;
          color: #1a7cfa;
        }
      }
      .signinBtn {
        border: 1px solid white;
        .MuiButton-label {
          font-family: "NotoSans-Bold";
          text-transform: none;
          color: white;
        }
      }
    }
  }
  .section_one_text {
    flex: 0 0 100%;
    padding: 0 20px;
    box-sizing: border-box;
  }
  .section_two_text {
    flex: 0 0 100%;
    padding: 0 20px;
    box-sizing: border-box;
  }
  .section_three_text {
    flex: 0 0 100%;
    padding: 0 20px;
    box-sizing: border-box;
  }
  .section_four_text {
    flex: 0 0 100%;
    padding: 0 20px;
    box-sizing: border-box;
  }
  .section_one_image {
    flex: 0 0 100%;
  }
  .section_two_image {
    flex: 0 0 100%;
  }
  .section_three_image {
    flex: 0 0 100%;
  }
  .section_four_image {
    flex: 0 0 100%;
  }
  .mobileSectionOne {
    display: block;
    margin-bottom: 10px;
    margin-right: -20px;
  }
  .webSectionOne {
    display: none;
  }
  .footer_content {
    display: block;
  }
  .navList {
    justify-content: space-between;
    margin-top: 25px;
    p {
      margin: 0;
    }
  }

  //header styles
  .webHeader {
    display: none;
  }
  .mobileHeader {
    display: block;
    .menuOverlay {
      position: fixed;
      top: 56px;
      left: 0;
      right: 0;
      bottom: 0;
      background: white;
      border-top: 2px solid #ccc;
    }
    .menuNavItems {
      padding: 20px 10px;
    }
    .mobileMenuItem {
      margin: 30px 15px;
      color: #1a7cfa;
    }
  }

  //Dashboard main styles
  .dashboardMain {
    margin-left: 0;
    padding-bottom: 0px;
    background: #fff;
  }

  //side Nav styles
  .sideNavbar {
    display: none;
  }

  //balance screen
  .balance-wrapper {
    .amountBlockWrapper {
      padding: 8px 10px;
    }
    .balancePill {
      padding: 15px 5px;
      text-align: center;
      span.fontBold {
        display: block;
        margin-left: 0;
      }
    }
    .MuiTabs-root {
      margin: 0 10px;
    }
    .MuiTab-root {
      min-width: unset;
    }
  }
  //transfer tab styles
  .transfer_main {
    padding: 24px 10px;
    .eachTransfer_Section {
      padding: 25px 15px;
    }
    .transferFormWrapper {
      display: block;
      margin-left: 0;
    }
    .transferFormContainer {
      padding: 15px 20px;
    }
    .amountInput {
      display: block;
    }
    .initiate_button {
      margin-top: 20px;
    }
  }

  //reward mobile
  .discount_main_sec {
    padding-right: 0px !important;
  }
  .rewards-wrapper {
    width: 100% !important;
    .rewardTab_options {
      flex-direction: column !important;
      .reward_option_wid {
        flex-direction: column;
      }
      .MuiFormGroup-root {
        width: 100%;
      }
      .MuiFormControlLabel-root {
        width: 33.3%;
        margin: 0px;
      }
      .no_discount_available {
        font-size: 17px;
        font-family: "NotoSans-regular";
      }
    }
    .discount_Item {
      width: 48%;
      min-width: 48%;
      min-height: 225px;
      margin: 0px auto;
      height: 225px;
      margin-left: 0px;
      margin-right: 0px;
      p {
        height: 40px;
        font-size: 15px;
      }
    }
    .discount_Item:nth-child(odd) {
      margin-right: 2%;
      margin-bottom: 25px;
    }
    .discount_Item:nth-child(even) {
      margin-left: 2%;
      margin-bottom: 25px;
    }
    .listItemwrapper {
      flex-direction: row;
      margin-left: 0px !important;
    }
  }

  .discount_imagewrapper {
    min-height: 80px;

    height: 100px;
  }

  .rewards-wrapper
    .rewardTab_options
    .MuiTypography-root.MuiFormControlLabel-label {
    font-size: 15px;
    color: #000;
    letter-spacing: 0.2px;
    font-family: "NotoSans-regular";
  }
  .rewards-wrapper button.MuiTab-textColorPrimary {
    width: 40%;
    margin: 0 5%;
  }
  .rewards-wrapper .MuiTabs-flexContainer {
    justify-content: left;
  }
  .rewards-wrapper {
    p.MuiTypography-root.MuiTypography-body1 {
      margin-left: 1rem;
      font-size: 20px;
    }
  }
  .reward_option_wid {
    button.MuiButtonBase-root.MuiButton-root.MuiButton-text {
      margin-left: auto;
      margin-top: 15px;
      height: 45px;
      border-radius: 4px;
      width: 120px;
      text-transform: capitalize;
    }
  }
  .PrivateSwitchBase-root-6 {
    padding-left: 0px !important;
  }
  .archived_label {
    padding-left: 1rem;
  }
  .new_discount_ip {
    width: 100% !important;
    display: block !important;
  }
  .new_discount_main {
    width: 100% !important;

    margin-bottom: 0px !important;

    flex-direction: column !important;
  }
  .select_desc {
    width: 100%;
  }
  .add_discount .select_desc .MuiFormControl-root {
    min-width: 100% !important;
  }
  .uploadfile {
    width: 100% !important;
  }
  label + .MuiInput-formControl {
    margin-top: 16px;
    font-weight: 500;
    font-size: 18px;
    color: #000000f0;
    letter-spacing: 1px;
    margin-bottom: 25px;
  }
  .MuiFormControl-marginNormal {
    margin-top: 0px !important;
  }
  .new_discount_ip {
    .MuiIconButton-root {
      padding-right: 0px !important;
      svg {
        height: 25px;
        width: 25px;
      }
    }
    .MuiInputLabel-formControl {
      font-size: 18px;
      font-family: "NotoSans-regular" !important;
    }
  }
  .add_discount {
    p {
      font-size: 20px !important;
    }
  }
  .table_desktop {
    display: none !important;
  }
  .table_mobile {
    display: block !important;

    .MuiTableContainer-root {
      overflow-x: inherit;
      margin-top: 20px;
    }
    .MuiTable-root {
      background-color: rgb(244, 249, 255);
    }
    .MuiTableCell-head {
      color: #0009 !important;
      font-weight: 600 !important;
      line-height: 18px !important;
      font-family: "NotoSans-regular" !important;
      font-size: 0.8rem !important;
    }
    .MuiTableCell-body {
      color: #000;
      font-weight: 600;
      font-size: 14px;
      padding-top: 0px;
    }
    .MuiTableCell-root {
      border-bottom: none;
      vertical-align: top;
    }
    .MuiTableBody-root {
      border: none;

      border-bottom: 2px dotted #ccc;
    }
    .date_table_border_bottom {
      border-bottom: none !important;
    }
  }
  .loyalty_wrapper {
    .lotality_btn_sec {
      button.MuiButtonBase-root.MuiButton-root.MuiButton-text {
        width: 100% !important;
      }
    }
  }
  .discount_sec {
    padding: 0px !important;
  }
  .new_discount_ip {
    .MuiFormHelperText-root.Mui-error {
      font-size: 13px !important;
      margin-top: -20px;
    }
  }
  .MuiPaper-root.MuiPaper-elevation1 {
    margin-top: 0px !important;
  }

  //balance start
  .amountBlockWrapper {
    display: block !important;
    p {
      display: block !important;
      width: 100%;
      border-top: 1rem solid #fff;
      margin-bottom: 40px;
    }
  }
  .balance_sec {
    .MuiTypography-body1 {
      margin-bottom: 0px !important;
    }
    .date_type {
      width: 48%;
      display: inline-flex !important;
      margin-right: 5px !important;
      .fontBold {
        padding-left: 7px;
        font-size: 13px;
      }
    }
    .time_type {
      margin-right: 0px !important;
      margin-left: 5px !important;
    }
    .balancePill {
      text-align: left !important;
      display: inline-flex !important;
      .current_font {
        margin-left: 50px;
      }
      .fontBold.current_font {
        margin-left: 22px;
      }
    }

    .current_font {
      margin-left: 50px;
    }
    .fontBold.current_font {
      margin-left: 50px;
    }
  }
  .menuNavItems {
    .mobile_side_nav_item {
      // color:#000 !important;
      p {
        font-size: 17px !important;
        letter-spacing: 0.7px;
        margin-left: 20px !important;
      }
    }
  }

  //balance end
}

.reward_option_wid {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

//reward 320px media start
@media (max-width: 359px) {
  .rewards-wrapper
    .rewardTab_options
    .MuiTypography-root.MuiFormControlLabel-label {
    font-size: 13px;
  }
  .reward_option_wid {
    .PrivateSwitchBase-root-6 {
      padding: 4px;
    }
  }
  .rewards-wrapper .discount_Item {
    min-height: 225px;
    height: 225px;
  }
  .discount_Item {
    p {
      font-size: 15px;
      height: 40px;
      overflow: hidden;
    }
  }
  .discount_main_sec {
    .rewardTab_options {
      .no_discount_available {
        font-size: 14px;
        font-family: "NotoSans-regular";
      }
    }
  }
  .amountBlockWrapper {
    .date_type .fontBold {
      font-size: 10px;
      padding-top: 4px;
    }

    .fontBold.current_font {
      font-size: 10px;
    }
  }

  .table_mobile .MuiTableCell-body {
    font-size: 10px;
  }
  .table_mobile .MuiTableCell-head {
    line-height: 16px !important;

    font-size: 0.7rem !important;
  }
}
//reward 320px media end

// merchant signup start
.dob_input {
  .MuiFormControl-marginNormal {
    width: 100%;
    margin-top: 14px;
  }
}

@media (min-width: 300px) and (max-width: 370px) {
  .signupWrapper {
    .MuiInputLabel-root,
    .MuiInputBase-input {
      font-size: 13px;
    }
  }
}
@media (min-width: 300px) and (max-width: 767px) {
  // side menu start
  .menuNavItems {
    .mobile_side_nav_item {
      &:focus {
        background-color: #e8f1fe !important;
        border-left: 5px solid #1a7cfa !important;
        outline: none;
      }
      &:hover {
        background-color: #e8f1fe !important;
        border-left: 5px solid #1a7cfa !important;
        outline: none;
      }
    }
  }
  // side menu end
}
@media (min-width: 300px) and (max-width: 767px) {
  .dob_input {
    margin: 13px auto !important;
  }
}
.dob_input {
  button {
    padding: 3px;
  }
}
// merchant signup end

// reward sec start
.reward_wid_sec {
  width: 92%;
  margin: auto;
}
// reward sec end
// balance sec start
.balance_wid_sec {
  width: 92%;
  margin: auto;
}
// balance sec end
// signup sec start
.sign_up_logo {
  width: 65px;
}
.sign_up_next_sec {
  margin-top: 30px;
  width: 100%;
  text-align: right;
}
.signup_wid_sec {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 85px;
  padding-bottom: 40px;
}
.merchent_wid_sec {
  width: 70%;
  align-items: center;
  padding: 30px;
  font-size: 14px;
}
.merchent_signup_ip {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
// signup sec end

@media screen and(max-width:390px) {
  .rewards-wrapper {
    width: 100% !important;
    .rewardTab_options {
      flex-direction: column !important;
      .MuiFormControlLabel-root {
        width: 33.3%;
        margin: 1px;
      }
    }
  }
}
